@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Play:wght@400;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --clr-white: #FFFFFF;
  --clr-black: #202020;
  --clr-blue: #6C63FF;
  --clr-blue-2: #4c46b6;
}

body {
  margin: 0;
  font-family: "Play";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #202020;
  border-style: solid;
}

/* ------------------------------------------------------ */
/* -- NAVIGATION -- */

nav {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  color: #680e04;
  background-color: #e3e3e3;
}

.nav__brand {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffb4ac;
  padding: 20px;
  background-color: #e3e3e3;
}

.nav__brand h1 {
  /* color: var(--clr-blue); */
  text-shadow: 5px 5px 10px rgba(229, 16, 245, 0.4);
  color: #632268;
  font-size: clamp(1.15em, 3vw, 2em);
  font-weight: 600;
}

.nav__connect {
  width: 175px;
  height: 50px;
  margin: 0 auto;
  

  /* background-color: var(--clr-blue); */
  background-color: #ce4f5b;
  color: #ffb4ac;
  border: none;
  border-radius: 4px;

  font-family: "Play";
  font-size: 1.10em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
}

.nav__connect:hover {
  /* background-color: var(--clr-blue-2); */
  background-color: var(--clr-blue-2);
}
/* ------------------------------------------------------ */
/* -- Footer -- */

.footer__copyright{  
  color: #632268;
  background-color: #e3e3e3;  
  text-align: center;
  font-size: 1.1rem;  
  font-weight: bold;
}

/* ------------------------------------------------------ */
/* -- FORM -- */

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3e3e3;
  min-height: 600px;
}

form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin: 0 25px
}

form input[type="text"] {
  width: 250px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;

  border: 1px solid #ce4f5b;
  border-radius: 4px;
  color: #632268;
  font-size: 1.10em;
}

form input[type="submit"] {
  /* background-color: var(--clr-blue); */
  background-color: #ce4f5b;
  color: #ffb4ac;
  width: 250px;
  height: 50px;
  margin: 10px 0;
  padding: 10px;

  border: none;
  border-radius: 4px;

  font-family: "Play";
  font-size: 1.10em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
}

form input[type="submit"]:hover {
  background-color: var(--clr-blue-2);
}

/* ------------------------------------------------------ */
/* -- IMAGE -- */

.image {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 90%;
  width: 512px;
  height: 512px;
  margin: 0 25px;
  border: 3px solid #ce4f5b;
  border-radius: 4px;

  overflow: hidden;
}

.image img {
  width: 512px;
  height: 512px;
  border-radius: 6px;
}

.image__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image__placeholder p {
  margin: 0 10px;
}

.label {
  color: #632268;
  font-size: 1.2em;
}
/* ------------------------------------------------------ */
/* -- TEXT -- */
p {
  display: flex;
  justify-content: center;
  align-items: center;
  
}

@media screen and (max-width: 768px) {
  .form {
    flex-direction: column;
  }
}